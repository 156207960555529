import React from "react"
import PageLayout from "../../components/PageLayout"
import { GatsbyImage, getSrc } from "gatsby-plugin-image"
import { Link, graphql } from "gatsby"
import PropTypes from "prop-types"
import PageHeader from "../../components/PageHeader"
import ShareButtons from "../../components/ShareButtons"

const MusicWork = ({ data }) => {
  const { work } = data
  const { blurb, title, releaseDate, slug, coverAlt } = work
  const { html } = work.body.childMarkdownRemark
  return (
    <PageLayout
      title={title}
      metaDescription={blurb}
      metaImage={getSrc(work.coverImage.ogImage)}
      actionItem="follow"
      fullWidth={false}
      contentPath={`music/${slug}`}
    >
      <PageHeader breadcrumb="Music" title={title} />

      <div className="row gy-3">
        <div className="col-md-4 col-12">
          <GatsbyImage image={work.coverImage.gatsbyImageData} alt={coverAlt} />
          <div className="w-100 text-center mt-3">
            <ShareButtons
              sites={["Twitter", "Facebook", "Email"]}
              content={`https://www.timsandberg.com/music/${slug}`}
            />
          </div>
        </div>
        <div className="col-md-8 col-12">
          <div className="musical-work-body">
            <div dangerouslySetInnerHTML={{ __html: html }} />
            <p>
              <small>Released {releaseDate}</small>
            </p>
          </div>
          <Link to="/music">
            <i className="fa fa-arrow-left fa-fw" /> Music Home
          </Link>
        </div>
      </div>
    </PageLayout>
  )
}

export const query = graphql`
  query MusicWorkBySlug($id: String!) {
    work: contentfulMusic(id: { eq: $id }) {
      releaseDate(formatString: "LL")
      coverImage {
        gatsbyImageData(layout: FULL_WIDTH)
        ogImage: gatsbyImageData(width: 1200, height: 627, cropFocus: CENTER)
      }
      coverAlt
      title
      slug
      body {
        childMarkdownRemark {
          html
        }
      }
    }
  }
`

MusicWork.propTypes = {
  data: PropTypes.object.isRequired,
  pageContext: PropTypes.object.isRequired,
}

export default MusicWork
